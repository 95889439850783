import {
  Button,
  SelectInput,
  SmrTable,
  TextInput,
  Drawer,
  styled,
  Icon,
} from 'shamrock-clover-ui';
import CapacityScoreChip from './CapacityScoreChip';
import { useContext, useState } from 'react';
import { SortOrder, Column, Row } from 'components/cloverTable/TableTypes';
import { CarrierCapacityContext } from '../context/CarrierCapacityContext';
import {
  CompanyText,
  StyledCheckmark,
  TableContainer,
} from '../CarrierCapacityStyles';
import CarrierProfileDrawerHeader from './Drawer/CarrierProfileDrawerHeader';
import DrawerSubheader from './Drawer/DrawerSubheader';
import { CompanyCapacityInfo, CarrierProfileTab } from '../CapacityTypes';
import CompanyDetails from './Drawer/CompanyDetails';
import DetailsTable from './Drawer/DetailsTable';
import AddLanesDrawerHeader from './Drawer/AddLanesDrawerHeader';
import { CarrierLoadHistory } from './Drawer/CarrierLoadHistory';
import {
  AddLaneAndCapacityContent,
  AddLaneForm,
  ContentContainer,
  CustomInput,
  InputsGroup,
  InputsTitles,
  ResultText,
  StyledTextContainer,
} from './Drawer/DrawerStyles';
import { AddCapacityForm, CapacityForm } from './CapacityForm';

const ActionButtons = () => (
  <div style={{ display: 'flex', gap: 20 }}>
    <Button buttonStyle="outlined">Cancel</Button>
    <Button buttonStyle="solid" color="green">
      Add Lane
    </Button>
  </div>
);

const CustomDrawer = styled(Drawer)`
  & > div:last-child {
    overflow-y: auto;
  }

  & > div:first-child > div:last-child {
    z-index: 99999;
  }
`;

const SearchCapacityResults = () => {
  const [openAddLane, setOpenAddLane] = useState(false);
  const [addCapacity, setAddCapacity] = useState(false);
  const handleRowClick = (index: number) => {
    setIsOpen(true);
    setSelectedRowIndex(index);
  };
  const { results } = useContext(CarrierCapacityContext);

  const tableRows: Row[] = results.map(
    (data: CompanyCapacityInfo, index: number) => ({
      cells: [
        {
          content: <CapacityScoreChip score={data.score} />,
          value: data.score,
        },
        {
          content: <CompanyText>{data.company}</CompanyText>,
          value: data.company,
        },
        {
          content:
            data.loadHistory === null ? '_' : data.loadHistory.toString(),
          value: data.loadHistory === null ? '_' : data.loadHistory,
        },
        {
          content: data.hq,
          value: data.hq,
        },
        {
          content: data.trucks.toString(),
          value: data.trucks,
        },
        {
          content: (
            <>
              {data.rtsprouser ? (
                <StyledCheckmark icon="checkCircleGreen" color="gray3" />
              ) : (
                <></>
              )}
            </>
          ),
          value: data.rtsprouser.toString(),
        },
        {
          content: data.mcleodId,
          value: data.mcleodId,
        },
        {
          content: (
            <>
              MC: {data.mc}
              <br />
              DOT: {data.dot}
            </>
          ),
          value: `${data.mc} ${data.dot}`,
        },
        {
          content: data.name,
          value: data.name,
        },
        {
          content: (
            <>
              {data.phone}
              <br />
              {data.email}
            </>
          ),
          value: `${data.phone} ${data.email}`,
        },
      ],
      onClick: () => handleRowClick(index),
    }),
  );
  const [isOpen, setIsOpen] = useState(false);
  const [sortedColumnIndex, setSortedColumnIndex] = useState(-1);
  const [sortDirection, setSortDirection] = useState<SortOrder>('desc');
  const [sortedTableRows, setSortedTableRows] = useState<Row[]>(tableRows);
  const [selectedRowIndex, setSelectedRowIndex] = useState<number>(0);
  const [drawerTabClicked, setDrawerTabClicked] =
    useState<CarrierProfileTab>('Details');

  const tableColumns: Column[] = [
    {
      key: 'score',
      label: 'Score',
      isSortable: true,
      width: 7,
    },
    {
      key: 'company',
      label: 'Company',
      isSortable: true,
      width: 12,
    },
    {
      key: 'loadHistory',
      label: 'Load History',
      isSortable: true,
      alignment: 'center',
      width: 9.3,
    },
    {
      key: 'hq',
      label: 'HQ',
      isSortable: false,
      alignment: 'left',
      width: 5.1,
    },
    {
      key: 'trucks',
      label: 'Trucks',
      isSortable: true,
      alignment: 'right',
      width: 6,
    },
    {
      key: 'rtsprouser',
      label: 'RTS Pro User',
      isSortable: true,
      alignment: 'center',
      width: 9.6,
    },
    {
      key: 'mcleodId',
      label: 'McLeod ID',
      isSortable: false,
      width: 9,
    },
    {
      key: 'mc/dot',
      label: 'MC/DOT',
      isSortable: false,
      width: 11,
    },
    {
      key: 'name',
      label: 'Name',
      isSortable: false,
      width: 15,
    },
    {
      key: 'contact',
      label: 'Contact',
      isSortable: false,
      width: 16,
    },
  ];

  const handleSort = (columnIndex?: number, direction?: SortOrder) => {
    if (columnIndex !== undefined && direction) {
      setSortedColumnIndex(columnIndex);
      setSortDirection(direction);
    }

    const sortedRows = [...sortedTableRows].sort((a, b) => {
      const aValue =
        columnIndex !== undefined ? a.cells[columnIndex].value : '';
      const bValue =
        columnIndex !== undefined ? b.cells[columnIndex].value : '';
      if (aValue > bValue) {
        return direction === 'asc' ? 1 : -1;
      }
      if (aValue < bValue) {
        return direction === 'asc' ? -1 : 1;
      }
      return 0;
    });
    setSortedTableRows(sortedRows);
  };

  const handleAddLaneDrawer = () => {
    setIsOpen(false);
    setOpenAddLane(true);
  };

  const handleAddCapacity = () => {
    setAddCapacity(true);
  };

  const handleOnCloseAddLane = () => {
    setOpenAddLane(false);
    setAddCapacity(false);
  };

  return (
    <>
      {results.length > 0 && (
        <>
          <Drawer
            open={isOpen}
            onClose={() => setIsOpen(false)}
            headerSeparatorType="none"
            width={'1161'}
            overlay={true}
            drawerHeaderContent={
              <CarrierProfileDrawerHeader
                companyInfo={results[selectedRowIndex]}
                selectedIndex={selectedRowIndex}
                setSelectedIndex={setSelectedRowIndex}
                searchCapacity={true}
              />
            }
          >
            <ContentContainer>
              <DrawerSubheader setTabClicked={setDrawerTabClicked} />
              {drawerTabClicked === 'Details' && (
                <>
                  <CompanyDetails companyDetails={results[selectedRowIndex]} />
                  <DetailsTable actionHandler={handleAddLaneDrawer} />
                </>
              )}
              {drawerTabClicked === 'Load History' && <CarrierLoadHistory />}
            </ContentContainer>
          </Drawer>
          <CustomDrawer
            open={openAddLane}
            onClose={handleOnCloseAddLane}
            headerSeparatorType="none"
            width={'883'}
            overlay={true}
            className="custom-drawer"
            drawerHeaderContent={
              <AddLanesDrawerHeader
                companyInfo={results[selectedRowIndex]}
                actionButtons={<ActionButtons />}
              />
            }
          >
            <ContentContainer>
              <AddLaneForm>
                <div>
                  <span style={{ fontSize: '24px', fontWeight: 600 }}>
                    Lane Details
                  </span>
                  <AddLaneAndCapacityContent>
                    <InputsTitles>Pickup</InputsTitles>
                    <InputsGroup>
                      <SelectInput
                        options={[
                          { option: 'City', optionName: 'City or Zip' },
                        ]}
                        value={{ option: 'City', optionName: 'City or Zip' }}
                        onOptionSelected={() => undefined}
                        width={133}
                        variant="filled"
                      />

                      <TextInput
                        variant="filled"
                        size={235}
                        value={'Fort Collins, CO'}
                      />
                    </InputsGroup>
                  </AddLaneAndCapacityContent>
                  <div style={{ paddingTop: '10px', marginBottom: '25px' }}>
                    <InputsTitles>Delivery</InputsTitles>
                    <InputsGroup>
                      <SelectInput
                        options={[
                          { option: 'City', optionName: 'City or Zip' },
                        ]}
                        value={{ option: 'City', optionName: 'City or Zip' }}
                        variant="filled"
                        onOptionSelected={() => undefined}
                        width={133}
                      />
                      <CustomInput
                        variant="filled"
                        size={235}
                        endIcon="triangleDown"
                      />
                    </InputsGroup>
                  </div>
                  <div style={{ paddingTop: '10px', marginBottom: '25px' }}>
                    <InputsTitles>Equipment</InputsTitles>
                    <InputsGroup>
                      <SelectInput
                        options={[
                          { option: 'City', optionName: 'City or Zip' },
                        ]}
                        value={{ option: 'City', optionName: 'City or Zip' }}
                        label=""
                        onOptionSelected={() => undefined}
                        variant="filled"
                        width={394}
                      />
                    </InputsGroup>
                  </div>
                  <div style={{ paddingTop: '10px', marginBottom: '25px' }}>
                    <InputsTitles>Manage Capacity</InputsTitles>
                    {addCapacity && (
                      <div style={{ marginTop: '10px' }}>
                        <Button
                          icon={
                            <Icon icon="deleteIcon" color="red" size="16" />
                          }
                          buttonStyle="outlined"
                          isRounded={true}
                          color="red"
                        >
                          Delete Capacity
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
                <div style={{ width: '95%', borderLeft: '1px solid #cccccc' }}>
                  <span
                    style={{
                      fontSize: '24px',
                      fontWeight: 600,
                      paddingLeft: '20px',
                    }}
                  >
                    Capacity Preferences
                  </span>
                  {addCapacity ? (
                    <AddCapacityForm />
                  ) : (
                    <CapacityForm onClick={() => handleAddCapacity()} />
                  )}
                </div>
              </AddLaneForm>
            </ContentContainer>
          </CustomDrawer>
        </>
      )}

      <StyledTextContainer>
        <ResultText>{results.length} Matching Carriers</ResultText>
      </StyledTextContainer>
      <TableContainer>
        <SmrTable
          columns={tableColumns}
          rows={sortedTableRows}
          footerOptions={{
            page: 1,
            rowsPerPageSelector: true,
            totalRowsDisplay: 'rows',
            customRowsPerPageOptions: [10, 25, 50, 75, 100],
          }}
          sortingOptions={{
            onSort: handleSort,
            sortedColumnIndex: sortedColumnIndex,
            sortDirection: sortDirection,
          }}
        />
      </TableContainer>
    </>
  );
};

export default SearchCapacityResults;
