import { Grid } from '@material-ui/core';
import { Chip, Button, Icon, styled } from 'shamrock-clover-ui';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.gray[10]};
  height: calc(100vh - 98px);
`;

export const CapacitySearchContainer = styled.div`
  padding: 16px 64px;
  max-width: 1648px;
`;

export const GridItem = styled(Grid)`
  padding: 8px;
`;

export const StyledChip = styled(Chip)<{ backgroundColor: string }>`
  font-size: 16px;
  color: white;
  font-weight: 800;
  max-height: 12px;
  max-width: 34px;
  min-width: 34px;
  background-color: ${({ backgroundColor }) =>
    backgroundColor && backgroundColor};
`;

export const StyledResetButton = styled(Button)`
  margin-top: 18px;
  white-space: nowrap;
  font-size: 16px;
  font-weight: 400;
`;


export const StyledCheckmark = styled(Icon)<{paddingLeft?: string}>`
  width: 16px;
  height: 16px;
  padding-left: 72px;
`;

export const ViewText = styled.div`
  font-size: 48px;
  font-weight: 300;
  color: ${({ theme }) => theme.colors.gray[50]};
`;

export const TextContainer = styled.div`
  display: flex;
  justify-content: start;
  height: 68px;
  line-height: 58px;
  width: auto;
  margin-top: 24px;
`;

export const TableContainer = styled.div<{ marginTop?: string}>`
  margin-top: ${({ marginTop }) => marginTop ? marginTop : '0px'};
  width: 100%;
  max-height: 565px;
  overflow-y: scroll;
  border-top: 1px solid #cccccc;
  thead {
    position: sticky;
    top: 0;
    z-index: 2;
    th {
      border-top: hidden;
      span {
        font-size: 16px;
      }
    }
  }
  tbody {
    td {
      font-size: 16px;
    }
  }
  div {
    position: sticky;
    bottom: 0;
  }
`;

export const CompanyText = styled.div`
  color: #0091ea;
`;