import { SecondaryNavigation, TextInput } from 'shamrock-clover-ui';
import styled from 'styled-components';

export const DrawerTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  > p {
    font-size: 24px;
    margin: 0;
  }
  padding: 16px 24px 16px 40px;
`;

export const DrawHeader = styled.div`
  display: flex;
  padding: 0 24px 0 40px;
  justify-content: space-between;
  border-top: 1px solid #999999;
  border-bottom: 1px solid #999999;
  align-items: center;
  height: 76px;
  > span {
    color: #fff;
  }
`;

export const ContentContainer = styled.div`
  padding: 16px 24px 16px 40px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin-top: 78px;
  overflow-y: auto;
`;

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  > span {
    font-size: 14px;
  }
`;

export const PaginationButton = styled.div`
  height: 41px;
  width: 41px;
  border-radius: 50%;
  background-color: #f9f9f9;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    cursor: pointer;
  }
  &:hover {
    background-color: #eaf7ff;
  }
`;

export const DrawerContentNavigation = styled(SecondaryNavigation)`
  margin: 0px
  padding: 8px 0px;
  border-bottom: 0;
  height: 17px;
`;

export const StyledTextContainer = styled.div`
  justify-content: center;
  height: 70px;
  display: flex;
  align-items: center;
`;

export const AddLaneForm = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const CustomInput = styled(TextInput)`
  .inputIconEnd {
    width: 6px;
    height: 6px;
    cursor: pointer;
    position: absolute;
    right: 8px;
  }
`;

export const InputsGroup = styled.div<{ gap?: number }>`
  display: flex;
  padding-right: 20px;
  gap: ${(props) => (props.gap ? props.gap + 'px' : '4px')};
  margin-top: 10px;
  padding-right: 0;
`;

export const InputsTitles = styled.span`
  font-weight: 700;
  font-size: 16px;
`;

export const ResultText = styled.div`
  font-size: 18px;
  font-weight: 400;
  color: #333333;
  text-align: center;
`;

export const AddLaneAndCapacityContent = styled.div<{ separator?: boolean }>`
  margin-top: 15px;
  margin-bottom: 25px;
  padding-bottom: ${(props) => props.separator && '20px'};
  border-bottom: ${(props) => props.separator && '1px solid #cccccc'};
`;

export const CapacityText = styled.span`
  font-weight: 400;
  font-size: 14px;
  display: block;
  color: #333333;
  padding-top: 10px;
  padding-bottom: 5px;
`;
