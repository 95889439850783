import { FC, HTMLAttributes, useState } from 'react';
import { Button, CommentBox, Icon } from 'shamrock-clover-ui';
import {
  AddLaneAndCapacityContent,
  CapacityText,
  CustomInput,
  InputsGroup,
  InputsTitles,
} from './Drawer/DrawerStyles';

interface CapacityFormI extends HTMLAttributes<HTMLDivElement> {
  onClick: () => void;
}

export const CapacityForm: FC<CapacityFormI> = ({ onClick }) => {
  return (
    <div style={{ paddingLeft: '20px' }}>
      <p style={{ fontWeight: 400, fontSize: '16px' }}>
        Add additional details about this carrier’s lane, such as trucks
        quantity, frequency, rate and load details.
      </p>
      <Button
        icon={<Icon icon="plus" color="blue" size="16" />}
        buttonStyle="outlined"
        isRounded={true}
        onClick={onClick}
      >
        Add Capacity
      </Button>
    </div>
  );
};

export const AddCapacityForm = () => {
  const [value, setValue] = useState('');
  return (
    <div style={{ padding: '10px' }}>
      <AddLaneAndCapacityContent separator={true}>
        <InputsTitles>Capacity</InputsTitles>
        <CapacityText>
          How many trucks can the carrier provide for this route?
        </CapacityText>
        <InputsGroup>
          <CustomInput variant="filled" placeHolder="000" size={349} />
        </InputsGroup>
      </AddLaneAndCapacityContent>
      <AddLaneAndCapacityContent separator={true}>
        <InputsTitles>Frequency</InputsTitles>
        <CapacityText>
          How often would the carrier haul this route?
        </CapacityText>
        <InputsGroup gap={12}>
          <CustomInput
            variant="filled"
            size={115}
            endIcon="triangleDown"
            placeHolder="000"
            label="Loads per"
          />
          <CustomInput
            variant="filled"
            size={200}
            endIcon="triangleDown"
            value="Timeframe"
          />
        </InputsGroup>
      </AddLaneAndCapacityContent>
      <AddLaneAndCapacityContent separator={true}>
        <InputsTitles>Rate</InputsTitles>
        <CapacityText>
          What is the carrier’s preferred compensation per load? Final pricing
          will be negotiated with a rep.
        </CapacityText>
        <InputsGroup>
          <CustomInput
            variant="filled"
            placeHolder="Trailer Type"
            endIcon="triangleDown"
            size={349}
          />
        </InputsGroup>
        <InputsGroup gap={12}>
          <CustomInput
            variant="filled"
            size={225}
            endIcon="triangleDown"
            placeHolder="$000"
            label="Rate"
            helperText="Optional"
          />
          <CustomInput
            variant="filled"
            size={94}
            endIcon="triangleDown"
            value="Flat rate"
          />
        </InputsGroup>
      </AddLaneAndCapacityContent>
      <AddLaneAndCapacityContent
        separator={true}
        style={{ borderBottom: 'none' }}
      >
        <InputsTitles>Load Details</InputsTitles>
        <CapacityText>
          Are there extra equipments or services they can provide?
        </CapacityText>
        <InputsGroup>
          <CustomInput
            variant="filled"
            placeHolder="Additional Services"
            endIcon="triangleDown"
            size={349}
            helperText="Optional"
          />
        </InputsGroup>
        <div style={{ marginTop: '20px' }}>
          <CommentBox
            placeholder="Tell us about any additional details or commodities they will not haul."
            value={value}
            onChange={(e) => setValue(e.target.value)}
            label="Optional"
            rows={4}
            characterLimit={300}
            background="gray10"
          />
        </div>
      </AddLaneAndCapacityContent>
    </div>
  );
};
