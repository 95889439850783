import {
  CompanyText,
  StyledCheckmark,
  TableContainer,
} from '../CarrierCapacityStyles';
import { Drawer, SmrTable, styled, Button, TextInput, SelectInput, Icon } from 'shamrock-clover-ui';
import { Column, Row } from 'components/cloverTable/TableTypes';
import { CarrierProfileTab, CompanyCapacityInfo } from '../CapacityTypes';
import { searchData } from '../CapacitySearchData';
import CarrierProfileDrawerHeader from './Drawer/CarrierProfileDrawerHeader';
import { ContentContainer } from './Drawer/DrawerStyles';
import DrawerSubHeader from './Drawer/DrawerSubheader';
import CompanyDetails from './Drawer/CompanyDetails';
import DetailsTable from './Drawer/DetailsTable';
import { CarrierLoadHistory } from './Drawer/CarrierLoadHistory';
import { useState } from 'react';
import AddLanesDrawerHeader from './Drawer/AddLanesDrawerHeader';

// TODO: When Antonio finishes RTD-3898 and RTD-3899, we will need to clean up the shared styling between the CarrierSearchResults and SearchCapacityResults components
// The copied styles are here to avoid merge conflicts with Antonio's work
// AddLanes Drawer could be a separate component

const AddLaneForm = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const AddLanesFormContent = styled.div`
  &:after {
    content: '';
    position: absolute;
    width: 1px;
    height: 100vh;
    background: #999999;
    right: 49%;
    top: 154px;
  }
`;

const AddLaneSelect = styled(TextInput)`
  height: 55px;
`;

const InputsTitles = styled.span`
  font-weight: 700;
  font-size: 15px;
  margin-bottom: 10px;
`;

const ActionButtons = () => (
  <div style={{ display: 'flex', gap: 20 }}>
    <Button buttonStyle="outlined">Cancel</Button>
    <Button buttonStyle="solid" color="green">
      Add Lane
    </Button>
  </div>
);

const CarrierSearchResults = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState<number>(0);
  const [drawerTabClicked, setDrawerTabClicked] =
    useState<CarrierProfileTab>('Details');
  const [openAddLane, setOpenAddLane] = useState(false);
  const results = searchData; // TODO: updated this to context dependent on API call

  const handleRowClick = (index: number) => {
    setIsOpen(true);
    setSelectedRowIndex(index);
  };

  const handleAddLaneDrawer = () => {
    setIsOpen(false);
    setOpenAddLane(true);
  };

  const carrierSearchTableColumns: Column[] = [
    {
      key: 'company',
      label: 'Company',
      isSortable: false,
      alignment: 'left',
      width: 19,
    },
    {
      key: 'hq',
      label: 'HQ',
      isSortable: false,
      alignment: 'right',
      width: 5,
    },
    {
      key: 'trucks',
      label: 'Trucks',
      isSortable: false,
      alignment: 'right',
      width: 8,
    },
    {
      key: 'rtsprouser',
      label: 'RTS Pro User',
      isSortable: false,
      alignment: 'left',
      width: 9,
    },
    {
      key: 'mcleodId',
      label: 'McLeod ID',
      isSortable: false,
      width: 9,
    },
    {
      key: 'mc/dot',
      label: 'MC/DOT',
      isSortable: false,
      width: 12,
    },
    {
      key: 'name',
      label: 'Name',
      isSortable: false,
      width: 18,
    },
    {
      key: 'contact',
      label: 'Contact',
      isSortable: false,
      width: 19,
    },
  ];

  const carrierSearchTableRows: Row[] = results.map(
    (data: CompanyCapacityInfo, index: number) => ({
      cells: [
        {
          content: <CompanyText>{data.company}</CompanyText>,
          value: data.company,
        },
        {
          content: data.hq,
          value: data.hq,
        },
        {
          content: data.trucks.toString(),
          value: data.trucks,
        },
        {
          content: (
            <>
              {data.rtsprouser ? (
                <StyledCheckmark icon="checkCircleGreen" color="gray3" />
              ) : (
                <></>
              )}
            </>
          ),
          value: data.rtsprouser.toString(),
        },
        {
          content: data.mcleodId,
          value: data.mcleodId,
        },
        {
          content: (
            <>
              MC: {data.mc}
              <br />
              DOT: {data.dot}
            </>
          ),
          value: `${data.mc} ${data.dot}`,
        },
        {
          content: data.name,
          value: data.name,
        },
        {
          content: (
            <>
              {data.phone}
              <br />
              {data.email}
            </>
          ),
          value: `${data.phone} ${data.email}`,
        },
      ],
      onClick: () => handleRowClick(index),
    }),
  );

  return (
    <>
      <Drawer
        open={isOpen}
        onClose={() => setIsOpen(false)}
        headerSeparatorType="none"
        width={'1161'}
        overlay={true}
        drawerHeaderContent={
          <CarrierProfileDrawerHeader
            companyInfo={results[selectedRowIndex]}
            selectedIndex={selectedRowIndex}
            setSelectedIndex={setSelectedRowIndex}
          />
        }
      >
        <ContentContainer>
          <DrawerSubHeader setTabClicked={setDrawerTabClicked} />
          {drawerTabClicked === 'Details' && (
            <>
              <CompanyDetails companyDetails={results[selectedRowIndex]} />
              <DetailsTable actionHandler={handleAddLaneDrawer} />
            </>
          )}
          {drawerTabClicked === 'Load History' && <CarrierLoadHistory />}
        </ContentContainer>
      </Drawer>
      <Drawer
            open={openAddLane}
            onClose={() => setOpenAddLane(false)}
            headerSeparatorType="none"
            width={'883'}
            overlay={true}
            drawerHeaderContent={
              <AddLanesDrawerHeader
                companyInfo={results[selectedRowIndex]}
                actionButtons={<ActionButtons />}
              />
            }
          >
            <ContentContainer>
              <AddLaneForm>
                <AddLanesFormContent>
                  <span style={{ fontSize: '24px', fontWeight: 600 }}>
                    Lane Details
                  </span>
                  <div style={{ paddingTop: '10px', marginBottom: '25px' }}>
                    <InputsTitles>Pickup</InputsTitles>
                    <div
                      style={{
                        display: 'flex',
                        paddingRight: '20px',
                        gap: 3,
                      }}
                    >
                      <SelectInput
                        options={[
                          { option: 'City', optionName: 'City or Zip' },
                        ]}
                        value={{ option: 'City', optionName: 'City or Zip' }}
                        label=""
                        onOptionSelected={() => undefined}
                        width={133}
                      />

                      <TextInput label={''} variant="filled" />
                    </div>
                  </div>
                  <div style={{ paddingTop: '10px', marginBottom: '25px' }}>
                    <InputsTitles>Delivery</InputsTitles>
                    <div
                      style={{
                        display: 'flex',
                        paddingRight: '20px',
                        gap: 3,
                      }}
                    >
                      <SelectInput
                        options={[
                          { option: 'City', optionName: 'City or Zip' },
                        ]}
                        value={{ option: 'City', optionName: 'City or Zip' }}
                        label=""
                        onOptionSelected={() => undefined}
                        width={133}
                      />
                      <AddLaneSelect label={''} variant="filled" />
                    </div>
                  </div>
                  <div style={{ paddingTop: '10px', marginBottom: '25px' }}>
                    <InputsTitles>Equipment</InputsTitles>
                    <div
                      style={{
                        display: 'flex',
                        paddingRight: '20px',
                        gap: 3,
                      }}
                    >
                      <div
                        style={{
                          width: '100%',
                          borderTopRightRadius: '5px',
                          borderTopLeftRadius: '5px',
                        }}
                      >
                        <SelectInput
                          options={[{ option: 'Van', optionName: 'Van' }]}
                          value={{ option: 'Van', optionName: 'Van' }}
                          label="Trailers"
                          onOptionSelected={() => undefined}
                        />
                      </div>
                    </div>
                  </div>
                  <div style={{ paddingTop: '10px', marginBottom: '25px' }}>
                    <InputsTitles>Manage Capacity</InputsTitles>
                  </div>
                </AddLanesFormContent>
                <div style={{ paddingLeft: '20px' }}>
                  <span style={{ fontSize: '24px', fontWeight: 600 }}>
                    Capacity Preferences
                  </span>
                  <p style={{ fontWeight: 400, fontSize: '16px' }}>
                    Add additional details about this carrier’s lane, such as
                    trucks quantity, frequency, rate and load details.
                  </p>
                  <Button
                    icon={<Icon icon="plus" color="blue" size="16" />}
                    buttonStyle="outlined"
                    isRounded={true}
                  >
                    Add Capacity
                  </Button>
                </div>
              </AddLaneForm>
            </ContentContainer>
          </Drawer>
      <TableContainer marginTop={'56px'}>
        <SmrTable
          columns={carrierSearchTableColumns}
          rows={carrierSearchTableRows}
          footerOptions={{
            page: 1,
            rowsPerPageSelector: true,
            totalRowsDisplay: 'rows',
            customRowsPerPageOptions: [10, 25, 50, 75, 100],
          }}
        />
      </TableContainer>
    </>
  );
};

export default CarrierSearchResults;
