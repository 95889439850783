import { Button, Icon, styled } from 'shamrock-clover-ui';
import lanesFakeData from './lanesFakeData.json'; // Delete this when we get the where to grab the real lanes data
import {
  Card,
  CardContent,
  CardHeaderTitles,
  CardsCapacityContainer,
  CustomButton,
  CardHeader,
} from './DetailsTableStyles';
import EditIcon from 'components/Icon';
import { FC } from 'react';

const columnsHeader = [
  'Pickup',
  'Delivery',
  'Equipment',
  'Capacity',
  'History',
  'Edit',
];

const EditPencilIcon = styled(EditIcon)`
  cursor: pointer;
`;
interface DetailsTableProps {
  actionHandler?: () => void;
}
const DetailsTable: FC<DetailsTableProps> = ({ actionHandler }) => {
  return (
    <div style={{ padding: '20px 0px 16px 0px' }}>
      <CardsCapacityContainer>
        <CardHeader>
          <div>
            <p>
              Lanes: <span>Viewing 6 of 6</span>
            </p>
          </div>
          <div>{/*Here the Sort Component*/}</div>
          <div>
            <CustomButton
              buttonStyle="solid"
              color="blue"
              icon={<Icon icon="plus" color="white" size="16" />}
              onClick={actionHandler}
            >
              Add Lane
            </CustomButton>
          </div>
        </CardHeader>
        <CardHeaderTitles>
          {columnsHeader.map((x) => (
            <span>{x}</span>
          ))}
        </CardHeaderTitles>
        {lanesFakeData.map((x) => (
          <Card>
            <CardContent>
              <span style={{ fontWeight: 600 }}>{x.pickupCity}</span>
              <Icon icon="arrowRight" size="16" color="gray4" />
            </CardContent>
            <CardContent>
              <span style={{ fontWeight: 600 }}>{x.deliveryCity}</span>
            </CardContent>
            <CardContent>
              <span style={{ fontWeight: 600 }}>
                {x.trailerTypes.join(',')}
              </span>
            </CardContent>
            {x?.capacity ? (
              <CardContent style={{ display: 'block' }}>
                <span style={{ fontWeight: 500 }}>
                  {`${x.trailerTypes.length} trucks, ${x.capacity.recurrence}, ${x.capacity.rate}, ${x.capacity.wrapType}, Tarps`}
                </span>
                <br />
                <span
                  style={{
                    fontWeight: 400,
                    fontSize: '12px',
                    color: '#333333',
                  }}
                >
                  Notes: {x.capacity.comment}
                </span>
              </CardContent>
            ) : (
              <CardContent>
                <Button
                  icon={<Icon icon="plus" color="blue" size="14" />}
                  buttonStyle="text"
                >
                  Add Capacity
                </Button>
              </CardContent>
            )}

            <CardContent
              style={{
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'start',
              }}
            >
              <span style={{ fontWeight: 600 }}>{x.laneType} </span>
              <span
                style={{
                  fontWeight: 400,
                  fontSize: '12px',
                  color: '#333333',
                }}
              >
                {x.email}
              </span>
              <span
                style={{
                  fontWeight: 400,
                  fontSize: '12px',
                  color: '#333333',
                }}
              >
                {x.createdAt}
              </span>
            </CardContent>
            <CardContent>
              <EditPencilIcon
                name="EditPencil"
                onHoverName="EditPencilHover"
                alt="EditIcon"
              />
            </CardContent>
          </Card>
        ))}
      </CardsCapacityContainer>
    </div>
  );
};

export default DetailsTable;
