import { styled, Button, Icon } from 'shamrock-clover-ui';
import { useSearchCapacityQueryParams } from 'hooks/useQueryParams';
import { SearchCapacityToolbarProps } from '../CapacityTypes';

const StyledButton = styled(Button)`
  margin-top: 18px;
`;

const SearchCapacityButton = ({ setResultsState }: SearchCapacityToolbarProps) => {
  const {
    queryParams: {
      pickupCity,
      pickupState,
      deliveryCity,
      deliveryState,
      trailerType,
    //   pickupDeadhead,  WILL NEED TO ADD THESE BACK IN WHEN onClick FUNCTION IS IMPLEMENTED
    //   deliveryDeadhead,
    },
  } = useSearchCapacityQueryParams();

  const onClick = () => {
    // TODO: Create request body to search capacity from the queryParams.
    // If pickupDeadhead and deliveryDeadhead are not provided, default to 75.
    console.log('Search Capacity');
    // TODO: onClick setResultsState to 'isLoading'
    // after the request is successful, setResultsState to 'resultsAvailable'
    setResultsState('resultsAvailable');
  };

  return (
    <StyledButton
      width="64px"
      height="36px"
      isRounded={true}
      disabled={
        !(
          pickupCity &&
          pickupState &&
          deliveryCity &&
          deliveryState &&
          trailerType
        )
      }
      onClick={onClick}
    >
      <Icon icon="search" color="white" />
    </StyledButton>
  );
};

export default SearchCapacityButton;
