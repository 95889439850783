import { FC } from 'react';
import { CompanyCapacityInfo } from '../../CapacityTypes';
import { styled } from 'shamrock-clover-ui';
import useSnackbar from 'hooks/useSnackbar';

export interface CompanyDetailsProps {
  companyDetails: CompanyCapacityInfo;
}

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 172px;
`;

const HeaderText = styled.div`
  font-size: 24px;
  font-weight: 600;
`;

const ColumnContainer = styled.div<{
  height?: string;
  width?: string;
  borderRight: boolean;
  padding?: string;
}>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  border-right: ${({ borderRight }) =>
    borderRight ? '1px solid #BBBBBB' : 'none'};
  padding: ${({ padding }) => padding};
`;

const InfoContainer = styled.div<{}>`
  display: flex;
  flex-direction: row;
`;

const InfoColumn = styled.div<{ width: string; height: string }>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
`;

const KeysText = styled.div<{ marginBottom?: string }>`
  font-size: 16px;
  font-weight: 400;
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : '3px')};
`;

const ValuesText = styled.div<{ marginBottom?: string, onClick?: () => void, onHover?: boolean }>`
  font-size: 16px;
  font-weight: 700;
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : '3px')};
  color: ${({ color }) => (color ? color : 'black')};
  ${({ onHover }) => onHover && `&:hover{
    background-color: blue;
  }`}'
`;

const QuickClipboardCopy = styled.span`
  cursor: pointer;
  &:hover {
    background-color: #eaf7ff;
  }
`;

const CompanyDetails: FC<CompanyDetailsProps> = ({ companyDetails }) => {
  const { showSnackbar } = useSnackbar();
  
  const handleCopyClick = async (text: string) => {
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(text);
    }
      showSnackbar({
        message: `Copied to Clipboard`,
        type: 'success',
        duration: 1000,
      });
  };

  return (
    <>
      <DetailsContainer>
        <ColumnContainer
          height="144px"
          width="298px"
          borderRight={true}
          padding="0 16px 28px 0"
        >
          <HeaderText>Company Info</HeaderText>
          <InfoContainer>
            <InfoColumn width="139px" height="107px">
              <KeysText>McLeod ID</KeysText>
              <KeysText>MC</KeysText>
              <KeysText>DOT</KeysText>
              <KeysText>Trucks</KeysText>
              <KeysText marginBottom="0px">McLeod Status</KeysText>
            </InfoColumn>
            <InfoColumn width="159px" height="107px">
              <ValuesText>{companyDetails.mcleodId}</ValuesText>
              <ValuesText>{companyDetails.mc}</ValuesText>
              <ValuesText>{companyDetails.dot}</ValuesText>
              <ValuesText>{companyDetails.trucks}</ValuesText>
              <ValuesText color="#CC5F00" marginBottom="0px">
                No Dispatch
              </ValuesText>
            </InfoColumn>
          </InfoContainer>
        </ColumnContainer>
        <ColumnContainer
          height="148px"
          width="322px"
          borderRight={true}
          padding="0 16px 24px 16px"
        >
          <HeaderText>Contact</HeaderText>
          <InfoContainer>
            <InfoColumn width="132px" height="111px">
              <KeysText>Contact Name</KeysText>
              <KeysText>Phone Number</KeysText>
              <KeysText>Email</KeysText>
              <KeysText>HQ</KeysText>
              <KeysText marginBottom="0px">Address</KeysText>
            </InfoColumn>
            <InfoColumn width="156px" height="111px">
              <ValuesText>{companyDetails.name}</ValuesText>
              <ValuesText>{companyDetails.phone}</ValuesText>
              {/* Will we need to consider truncation for long email addresses? */}
              <ValuesText>{companyDetails.email}</ValuesText>
              <ValuesText>{companyDetails.hq}</ValuesText>
              {/* Look in Shipper Portal for state value dropdown to convert state abbreviation to full name */}
              <ValuesText marginBottom="0px">Pottsville, PA 17901</ValuesText>
            </InfoColumn>
          </InfoContainer>
        </ColumnContainer>
        <ColumnContainer
          height="172px"
          width="399px"
          borderRight={false}
          padding="0 0 0 16px"
        >
          <HeaderText>Status</HeaderText>
          <InfoContainer>
            <InfoColumn width="158px" height="89px">
              <KeysText>90 Day Load History</KeysText>
              <KeysText>CAM</KeysText>
              <KeysText>RTS Pro Account</KeysText>
              <KeysText marginBottom="0px">Active Users</KeysText>
            </InfoColumn>
            <InfoColumn width="225px" height="89px">
              <ValuesText>4</ValuesText>
              <ValuesText>
                <QuickClipboardCopy
                onClick={() => {
                  handleCopyClick('jparker@ryantrans.com');
                }}
                >jparker@ryantrans.com</QuickClipboardCopy>
              </ValuesText>
              <ValuesText>Freight Quoting</ValuesText>
              <ValuesText marginBottom="0px">14</ValuesText>
            </InfoColumn>
          </InfoContainer>
          <InfoContainer>
            <InfoColumn width="91px" height="38px">
              <KeysText marginBottom="0px">Last Update:</KeysText>
            </InfoColumn>
            <InfoColumn width="308px" height="38px">
              <KeysText marginBottom="0px">mroesner@ryantrans.com</KeysText>
              <KeysText>8/24/2020, 1:51 pm</KeysText>
            </InfoColumn>
          </InfoContainer>
        </ColumnContainer>
      </DetailsContainer>
    </>
  );
};

export default CompanyDetails;
