import {
  Container,
  CapacitySearchContainer,
  ViewText,
  TextContainer
} from './CarrierCapacityStyles';
import SearchCapacityToolbar from './components/SearchCapacityToolbar';
import SearchCapacityResults from './components/SearchCapacityResults';
import CarrierCapacityWrapper from './context/CarrierCapacityContext';
import { useState } from 'react';
import { resultsState } from './CapacityTypes';

const SearchCapacity = () => {
  const [resultsState, setResultsState] = useState<resultsState>('initialView');
  return (
    <CarrierCapacityWrapper>
      <Container>
        <CapacitySearchContainer>
          <SearchCapacityToolbar setResultsState={setResultsState} />
          {resultsState === 'initialView' ? (
            <>
              <TextContainer>
                <ViewText>
                  Add details to find carriers with matching lanes and capacity
                </ViewText>
              </TextContainer>
            </>
          ) : (
            <SearchCapacityResults />
          )}
        </CapacitySearchContainer>
      </Container>
    </CarrierCapacityWrapper>
  );
};

export default SearchCapacity;
