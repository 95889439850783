import { NewPickupPicker } from 'features/search/location/NewPickupPicker';
import { styled } from 'shamrock-clover-ui';
import { PlaceTypes } from 'features/search/location/LocationPicker';
import { useSearchCapacityQueryParams } from '../../../hooks/useQueryParams';
import DeadheadPicker from './DeadheadPicker';
import { Flex } from '../../../components/Flex';
import { NewDeliveryPicker } from 'features/search/location/NewDeliveryPicker';
import EquipmentPicker from './EquipmentPicker';
import SearchCapacityButton from './SearchCapacityButton';
import ResetButton from './ResetButton';
import { SearchCapacityToolbarProps } from '../CapacityTypes';

const ToolbarContainer = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.gray[20]};
  min-height: 55px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
  padding: 16px;
  gap: 16px;
`;

const SearchCapacityToolbar = ({ setResultsState }: SearchCapacityToolbarProps)  => {
  const { setQueryParams, queryParams, clearQueryParams } = useSearchCapacityQueryParams();
  
  const updatePickupLocation = (
    city: string,
    state: string,
    postalCode?: string,
  ) => {
    const { pickupCity, pickupState, pickupPostalCode, ...restQueryParams } =
    queryParams;

  const newParams = {
    ...restQueryParams,
    ...(city && { pickupCity: city }),
    ...(state && { pickupState: state }),
    ...(postalCode && { pickupPostalCode: postalCode }),
  };

  setQueryParams(newParams);
};

  const updateDeliveryLocation = (
    city: string,
    state: string,
    postalCode?: string,
  ) => {
    const {
      deliveryCity,
      deliveryState,
      deliveryPostalCode,
      ...restQueryParams
    } = queryParams;

    const newParams = {
      ...restQueryParams,
      ...(city && { deliveryCity: city }),
      ...(state && { deliveryState: state }),
      ...(postalCode && { deliveryPostalCode: postalCode }),
    };

    setQueryParams(newParams);
  };

  const isPickupLocationSelected: boolean = !!queryParams.pickupCity && !!queryParams.pickupState;
  const isDeliveryLocationSelected: boolean = !!queryParams.deliveryCity && !!queryParams.deliveryState;
  
  const onClick = () => {
    clearQueryParams();
    setResultsState('initialView');
  };

  return (
    <ToolbarContainer flexWrap="wrap">
      <Flex width="324px" justifyContent="space-between">
        <NewPickupPicker
          value={queryParams}
          updatePickupLocation={updatePickupLocation}
          placeType={PlaceTypes.ALL}
          isPickupLocationSelected={isPickupLocationSelected}
        />
        <DeadheadPicker type="pickup" />
      </Flex>
      <Flex width="324px" height="55px" justifyContent="space-between">
        <NewDeliveryPicker
          value={queryParams}
          updateDeliveryLocation={updateDeliveryLocation}
          placeType={PlaceTypes.ALL}
          isDeliveryLocationSelected={isDeliveryLocationSelected}
        />
        <DeadheadPicker type="delivery" />
      </Flex>
      <EquipmentPicker />
      <SearchCapacityButton setResultsState={setResultsState} />
      <ResetButton onClick={onClick}/>
    </ToolbarContainer>
  );
};

export default SearchCapacityToolbar;
